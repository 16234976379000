@import '../../sass/mixins';
@import '../../sass/variables';

.task-info-plate {
  position: fixed;
  top: 76px;
  right: -600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  transition: right .5s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: $black-darkest !important;
  @include flex;
  @include size(584px, calc(100vh - 76px));

  .custom_select {
    .btn.secondary {
      border: 1px solid rgba(27, 170, 240, 0.3);
      background: rgba(27, 170, 240, 0.05);

      input[type=text] {
        background: rgba(27, 170, 240, 0.005); } } }

  .tabs_desc {
    position: relative;
    .first_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .toggler {
        .toggler_elem {
          padding: 8px; } }

      .iconsBar {
        @include flex(space-between);

        .count {
          font-size: 14px;
          line-height: 32px;
          font-weight: 400;
          cursor: default; }

        .attachments {
          @include flex();

          .icon {
            margin-left: 2px;
            @include size(32px, 32px);
            background-image: url('../../img/attach-blue_darker.svg');
            background-position: -3px 0;
            background-size: 30px;
            background-repeat: no-repeat; } }

        .comments {
          @include flex();

          .icon {
            margin-left: 2px;
            @include size(32px, 32px);
            background-image: url('../../img/comment.svg');
            background-position: 4px -2px;
            background-size: 23px;
            background-repeat: no-repeat; } }

        .bell {
          margin-left: 8px;
          @include size(32px, 32px);
          background-image: url('../../img/bell-blue_darker.svg');
          background-position: -3px 0;
          background-size: 30px;
          background-repeat: no-repeat; } }

      .task_status_wrapper {
        .task_status {
          width: 140px;
          height: 32px;
          line-height: 32px;

          &.disabled {
            cursor: default; } } }

      .custom_select {
        &.disabled {
          div {
            cursor: default; } } } }

    .second_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .input_div {
        width: 100%; } } }

  .comment_list {
    margin: 0;
    padding-right: 5px; }

  &.is_shown {
    right: 0; }

  .grid {
    grid-template-columns: 120px 170px 1fr 1fr;
    column-gap: 16px;
    padding: 0; }

  .close_btn {
    margin: 0;
    @include absolute(10px, 24px); }

  .taskInfoPlateBottomRowButtons {
    grid-column: 1/5;
    @include flex(flex-end); }

  .task_name {
    textarea {
      margin-bottom: 16px;
      font-family: Roboto, sans-serif;
      cursor: pointer;
      transition: .5s ease;
      font-size: 14px;
      border: 1px solid #E0E0E0;

      &:focus {
        border: 1px solid #1BAAF0;
        box-shadow: 0 0 0 2px #99D7F5;
        padding: 0; }

      &:not(:empty) {
        background-color: #F3FAFE;
        border: 1px solid #99D7F5; }

      &:focus {
        cursor: text; }

      &.disabled {
        background: white; } }

    .close_button {
      position: relative;
      width: 100%;
      margin-bottom: 8px;

      .close_panel {
        background-image: url('../../img/x.svg');
        width: 24px;
        height: 24px;
        cursor: pointer; }

      .MuiSvgIcon-root {
        color: grey;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0; } }

    textarea {
      border: 1px solid #E0E0E0;
      width: 100%;
      resize: none;
      font-size: 14px;
      padding-bottom: 3px;

      &:focus {
        border: 1px solid $blue-main;
        padding: 0; }

      &:not(:empty) {
        background-color: #F3FAFE;
        border: 1px solid #99D7F5; } } }

  .task_description {
    color: #212121;
    opacity: .8;
    max-height: 54px;
    font-size: 15px;
    line-height: 18px;
    min-height: 18px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
    @include ellipsis(3); }

  .toggler {
    height: 32px;

    .toggler_elem {
      line-height: 14px;
      font-size: 13px;
      padding: 8px 0; }

    &.priority_9 {
      border-color: #FF0F00; }

    &.priority_4 {
      border-color: #FF0F00; } }

  .priority_select {
    .priority {
      height: 32px;
      border: 1px solid $blue-main;
      border-radius: 6px;
      justify-content: center;
      cursor: pointer;
      padding: 8px;

      .priority_img {
        width: 16px !important;
        height: 16px !important;
        background-position: -4px -4px;
        margin-right: 4px;
        background-repeat: no-repeat; } }

    .custom_select_item {
      color: $blue-main;
      position: relative;
      font-size: 15px;

      &.selected_item {
        opacity: .6; }

      &:last-of-type {
        color: #FF0F00; } } }

  .task_date_time {
    grid-column: 2/4; }

  .info_tab {
    row-gap: 8px;
    margin-top: 30px;
    .dependencies {
      grid-column: 2/5;

      .firstRow {
        display: flex;

        .toggler {
          margin: 4px 0;

          .toggler_elem {
            padding: 8px 8px; } } } }

    .taskWorkLoad {
      grid-column: 2/5;
      .innerComponents {
        display: flex;
        align-items: center;

        .input_div {
          width: 98px;
          height: 40px;
          padding: 0 12px;

          &.disabled {
            background: rgba(27, 170, 240, 0.05); } }

        .toggler {
          margin: 0 8px;
          width: 153px;
          height: 40px;

          .toggler_elem {
            padding: 12px 8px; } } } }

    .date_time {
      div {
        background: rgba(27, 170, 240, 0.05);
        font-size: 14px; } }

    .input_with_unit {
      div {
        background: rgba(27, 170, 240, 0.05); } }

    .title {
      grid-column: 1/2;
      color: #5F6368;
      display: flex;
      line-height: 26px;
      font-size: 13px; }

    .custom_select {
      grid-column: 2/5;
      flex-grow: 0 !important;

      &.author {
        width: 234px; } }

    .executor_with_delegate {
      grid-column: 1/5;
      grid-template-columns: 112px 170px 1fr 1fr;

      .executor_select {
        grid-column: 1/5;
        grid-template-columns: 112px 170px 1fr 1fr;
        justify-content: center;
        column-gap: 24px;

        .title {
          grid-column: 1/2;
          justify-self: initial;
          align-self: center; }

        .executor_select_with_btn {
          justify-content: left;
          grid-column: 2/5;

          .input_div {
            width: 234px; }

          .btn.secondary {
            width: 234px; }

          p {
            color: $blue-main;
            margin: 0 16px;
            line-height: 40px;
            cursor: pointer;
            font-size: 13px; } } }

      .MuiCollapse-container {
        grid-column: 1/5;

        .title {
          grid-column: 1/2;
          justify-self: initial;
          align-self: auto; } }

      .MuiCollapse-wrapperInner {
        grid-template-columns: 120px 170px 1fr 1fr;
        column-gap: 24px;
        margin-top: 8px;

        input {
          width: 234px; }

        .title {
          height: 40px;
          line-height: 40px;

          .right_plate_info_item_icon {
            @include flex();
            align-self: center; } }

        .entrusted_select_with_btn {
          margin-left: -8px;

          .input_div {
            width: 234px; } } } }

    .dependencies {
      .secondRow {
        margin-top: 8px; }

      .thirdRow {
        margin-top: 8px;

        .dependenciesItem {
          @include flex(space-between, center);

          .content {
            font-size: 14px;
            line-height: 16px;
            color: $blue-main; }

          .deleteButton {
            @include size(32px, 32px);
            background-image: url('../../img/x.svg');
            background-position: 1px 0;
            background-size: 30px;
            background-repeat: no-repeat;
            cursor: pointer; } } } } }

  .checklist {
    margin: 16px 0;

    .progressBar {
      display: flex;
      height: 24px;

      .digit_progress {
        font-size: 16px;
        margin-right: 8px;
        line-height: 24px;
        cursor: default; }

      .bar {
        position: relative;
        width: 100%;
        margin-top: 5px;

        .all_time {
          margin: inherit;
          position: absolute;
          width: 100%;
          height: 4px;
          background: rgba(27, 170, 240, 0.1);
          border-radius: 4px; }

        .completed {
          transition: .5s ease;
          margin: inherit;
          position: absolute;
          height: 4px;
          background: $blue-main;
          border-radius: 4px; } } }

    .header {
      color: $blue-main;
      display: flex;
      height: 40px;
      width: 100px;
      padding: 8px 12px;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 8px;
      cursor: pointer;

      .expandedCheckListItems {
        background-image: url('../../img/up.svg');
        cursor: pointer; }

      .collapsedCheckListItems {
        background-image: url('../../img/down.svg'); }

      div:first-child {
        padding-top: 4px; }

      div:last-child {
        width: 24px;
        height: 24px;
        background-position: 2px 1px;
        background-size: 20px;
        background-repeat: no-repeat; } }

    #checkItems {
      margin-top: 8px;

      .checkItem {
        display: flex;
        line-height: 18px;
        justify-content: space-between;

        .input_div {
          padding: 0 12px; }

        &:hover {
          .deleteButton {
            visibility: visible; } }

        .deleteButtonBox {
          display: flex;

          .deleteButton {
            align-self: center; } }

        .checkItemContent {
          display: flex;
          line-height: 32px;

          .checkbox_item {
            margin: 0;

            .checkbox_icon {
              @include size(30px, 30px);
              cursor: pointer;
              margin: 0;
              background-image: url('../../img/checkbox empty.svg');
              background-repeat: no-repeat;
              background-size: 30px;

              &:hover {
                background-image: url('../../img/checkbox ready.svg'); }

              &.checked {
                background-image: url('../../img/checked1.svg'); } } }

          .orderNumber {
            margin-left: 8px;
            margin-right: 3px;
            cursor: default;

            &.asInput {
              line-height: 40px; } }

          .nameItem {
            cursor: pointer; } }

        .deleteButton {
          visibility: hidden;
          width: 16px;
          height: 16px;
          background-image: url('../../img/x.svg');
          background-position: -2px -2px;
          background-size: 20px;
          background-repeat: no-repeat;
          cursor: pointer; } }

      #addCheckItem {
        margin-top: 8px;
        height: 40px;
        padding: 8px;
        display: flex;
        width: 150px;
        cursor: pointer;

        .addNewRowIcon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background-image: url('../../img/plus.svg'); }

        .addNewRowText {
          font-size: 14px;
          line-height: 25px;
          color: $blue-main; } } } }

  .full_width_components {
    .description {
      textarea {
        border-radius: 6px;
        position: relative;
        padding: 12px;
        width: 100%;
        max-height: 400px;
        font-family: Roboto,sans-serif;
        font-size: 14px;
        resize: none;
        line-height: 17px;
        cursor: pointer;
        overflow: auto !important;
        transition: .5s ease;

        &:focus {
          border: 1px solid $blue-main;
          cursor: text;
          padding: 12px; }

        &:not(:empty) {
          background-color: #F3FAFE;
          border: 1px solid #99D7F5; }

        &.collapsed {
          max-height: 120px; } }

      div.input_div {
        @include ellipsis(); } } } }

.comments_tab {
  margin-top: 16px; }

.comment_data {
  margin: 16px 0;
  border-radius: 6px;
  flex-wrap: wrap;
  position: relative;
  @include flex(center);

  &.is_drag_enter {
    border: 1px dashed $blue-main; }

  &.with_parent {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  textarea {
    resize: none;
    padding-top: 11px;
    font-family: Roboto, sans-serif;
    background: transparent;
    @include size(calc(100% - 24px), 118px); }

  input[type="file"] {
    display: none; }

  label {
    display: block;
    font-size: 15px;
    color: $blue-main;
    border-top: $grey_border;
    border-right: $grey_border;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    @include size(145px, 50px); }

  p {
    color: rgba(0, 0, 0, 0.3); }

  .comment_actions {
    flex-grow: 1;
    padding-left: 16px;
    padding-right: 10px;
    border-top: $grey_border;
    @include flex(space-between, center);

    .btn {
      width: 117px; } } }

.comment_files_list {
  width: 100%;
  background: #EDEDED;
  max-height: 141px;
  overflow: auto;
  border-top: $grey_border; }

.comment_list {
  height: 100%;
  overflow: auto; }

.comment {
  padding: 16px;
  border: $grey_border;
  border-radius: 6px;
  transition: border-color .2s ease, box-shadow .2s ease;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0; }

  &:hover {
    box-shadow: 0 0 10px rgba(41, 47, 76, 0.1);

    .answer_comment {
      opacity: 1; }

    .answer_comment_bot {
      opacity: 1; }

    .comment_delete {
      display: inline-block; } }

  &.my_comment {
    &:hover {
      .comment_date {
        display: none; } } }

  &--system {
    border: none !important;
    background: #dadada;
    border-radius: 40px !important;
    text-align: center;
    padding: 14px 16px !important;

    .comment_head {
      display: none; }

    .comment_text {
      margin-top: 6px !important; }

    .attachments {
      display: none; } } }

.comment_head {
  margin-bottom: 20px;
  @include flex; }

.comment_author {
  flex-direction: column;
  margin-left: 8px;
  @include flex(space-between);

  &_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; }

  &_role {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 16px; } }

.answer_comment {
  font-size: 12px;
  cursor: pointer;
  color: $blue-main;
  margin-left: 8px;
  height: 15px;
  line-height: 25px;
  opacity: 0; }

.answer_comment_bot {
  margin-left: 8px;
  height: 18px;
  line-height: 25px;
  opacity: 0; }

.but_answer_comment_bot {
  color: $blue-main;
  cursor: pointer;
  font-size: 12px;
  background: none; }

.comment_date_delete_block {
  margin-left: auto;
  height: 15px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  text-align: left;

  .comment_delete {
    display: none;
    cursor: pointer; } }

.comment_body {
  .attachments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px; }

  .comment_text {
    margin-top: 16px;
    font-size: 15px;
    color: #353535;
    white-space: pre-line; } }

.answer_to {
  margin-bottom: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none;
  padding: 0 8px;
  @include flex(space-between, center);

  .close_btn {
    @include size(16px, 16px);
    margin-left: 8px;
    display: inline-table;
    position: relative;
    top: initial;
    right: initial; } }

.parent_comment {
  max-width: inherit;
  border-left: 2px solid $blue-main;
  padding-left: 8px;
  flex-direction: column;
  overflow: hidden;
  height: 44px;
  @include flex(space-between);

  .parent_comment_author {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; }

  .commented_text {
    @include ellipsis; } }

.add_new_comment {
  margin-bottom: 16px;

  .main_part {
    display: flex;

    &.empty {
      height: 36px; }

    input[type="file"] {
      display: none; }

    .send_button {
      cursor: pointer;
      padding-left: 5px;
      margin: 5px 8px;
      width: 36px;
      height: 36px;
      line-height: 36px;

      .MuiSvgIcon-root {
        color: $blue-main; } }

    .attach_file {
      cursor: pointer;
      margin: 5px 8px;
      width: 36px;
      height: 36px;
      line-height: 36px;

      .attachment_icon {
        width: 25px;
        height: 25px;
        background-image: url('../../img/attach.svg');
        background-position: -2px -2px;
        background-size: 30px;
        background-repeat: no-repeat; } }

    .textarea_files {
      width: 100%;
      border-radius: 18px;
      border: 1px solid #E0E0E0;
      padding: 8px 8px 0;

      textarea {
        padding: 0 2px 2px 2px;
        background: rgba(0, 0, 0, 0.005);
        font-family: Roboto, san-serif;
        font-size: 13px;
        width: 100%;
        height: 36px;
        resize: none; }

      .attachments {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        max-width: 415px; } } } }

.attachment {
  background: $blue-dark;
  cursor: pointer;
  border-radius: 20px;
  height: 32px;

  .icon {
    background: white;
    border-radius: 16px;
    @include size(24px, 24px);
    position: relative;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 4px;
    background-image: url('../../img/attach-blue_darker.svg');
    background-position: 0 0;
    background-size: 23px;
    background-repeat: no-repeat; }

  .content {
    font-size: 13px;
    width: 85px;
    padding: 0 8px;
    overflow: hidden;
    line-height: 32px;
    color: $black-darkest;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }

  .deleteAttachment {
    margin-left: 8px;
    margin-top: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url('../../img/x-black.svg');
    background-position: 0 4px;
    background-size: 17px;
    background-repeat: no-repeat; } }

.taskInfoPlateBottomRowButtons {
  margin-top: 16px;
  margin-bottom: 16px; }

.endRepeatTask {
  display: flex;

  &:first-child {
    margin-right: 8px; } }

.repeat_first_field {
  &.standart {
    width: 130px; }

  &.fullWidth {
    width: 241px; } }

.repeat_task_window {
  width: 352px;
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);

  .firstRow {
    @include flex(space-between);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    cursor: default;

    > :last-child {
      cursor: pointer;
      @include size(24px, 24px);
      background-image: url('../../img/x-black.svg');
      background-position: 0px -3px;
      background-size: 30px;
      background-repeat: no-repeat; } }

  .secondRow {
    @include flex(space-between);
    height: 40px;
    line-height: 40px;
    color: $black-darker;
    margin: 8px 0;
    cursor: default;

    .icon {
      @include size(30px, 40px);
      @include flex(center, center);
      background-image: url('../../img/task.svg');
      background-position: 2px 7px;
      background-size: 25px;
      background-repeat: no-repeat; }

    .name {
      font-size: 13px; }

    .input_div {
      width: 156px;
      cursor: pointer;

      input {
        padding: 10px; } } }

  .thirdRow {
    @include flex();
    line-height: 40px;
    margin: 16px 0;
    cursor: default;
    color: $black-darker;

    .input_div {
      @include size(40px, 40px);
      margin: 0 8px;

      input {
        text-align: center;
        padding: 0 12px; } } }

  .weekdays_container {
    margin: 8px 0;
    height: 40px; }

  .month {
    width: 280px;
    margin: 8px 0;
    @include flex();
    flex-wrap: wrap;

    .dayOfMonth {
      @include size(40px, 32px);
      @include flex(center, center);
      cursor: pointer;
      color: $black-middle;
      border-bottom: 1px solid $black-lighter;
      border-right: 1px solid $black-lighter;

      &:nth-child(-n+7) {
        border-top: 1px solid $black-lighter; }

      &:nth-child(7n+1) {
        border-left: 1px solid $black-lighter; }

      &.selected {
        background-color: $blue-lightest;
        color: $blue-darker; } } }

  .bottomRow {
    @include flex(space-between);
    margin-top: 16px;

    & > button {
      width: 100%; }

    > :last-child {
      margin-left: 8px; } } }

.info_attach_file {
  @include flex(center, center);
  height: 36px;
  width: 90px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid $black-lighter;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  &:hover {
    border-color: $black-darkest; } }

.confirm_edit_repeat_task_window {
  width: 352px;
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);

  .firstRow {
    @include flex(space-between);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    cursor: default; }

  .secondRow {
    @include flex(space-between);
    line-height: 16px;
    color: $black-darkest;
    margin: 16px 0;
    cursor: default; }

  .bottomRow {
    @include flex(space-between);
    margin-top: 16px;

    & > button {
      width: 100%; }

    > :last-child {
      margin-left: 8px; } } }

.right_plate_info_item_icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;

  &.executor {
    background-image: url('../../img/person.svg'); }

  &.author {
    background-image: url('../../img/person.svg'); }

  &.project {
    background-image: url('../../img/folder.svg'); }

  &.section {
    background-image: url('../../img/folder.svg'); }

  &.begin_date {
    background-image: url('../../img/calendar.svg'); }

  &.end_date {
    background-image: url('../../img/calendar.svg'); }

  &.task_work_load {
    background-image: url('../../img/bi_percent.svg'); }

  &.provide_to {
    background-image: url('../../img/v right.svg'); } }

.task-info-plate__preloader {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 6px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible; }

    .preloader-local {
      opacity: $preloaderSlowReqOpacity;

      > div {
        border-color: $blue-main transparent transparent transparent; } } }

